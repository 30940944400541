export default {
  background: "#EEE",
  color: "#333",
  color2: "#fff8cb",
  color3: "#FFFFFF",
  fill: "#000",
  opacity: 1,
  filter: "",
  filter2: "",
};
