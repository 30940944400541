export default {
  background: "#333",
  color: "#FFF",
  color2: "#000000",
  color3: "#000000",
  fill: "#FFF",
  opacity: 0.1,
  filter: "brightness(0) invert(1)",
  filter2: "brightness(100%) hue-rotate(30deg) invert(0%)",
};
